import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import moment from 'moment';
import { useQuery,useMutation,useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase, { auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";


const GETAPARTMENT = gql`
query getapartments {
  apartment(where: {user_id: {_is_null: false}}) {
    id
    number
    updated_at
    created_at
    building_id
    building {
      created_at
      id
      updated_at
      name
    }
    user_id
    user {
      first_name
      last_name
      id
    }
  }
}
`;

const INSERTVISIT = gql`
mutation inservisit($object: visits_insert_input!) {
  insert_visits_one(object: $object) {
    apartment_id
    arrives_at
    arrives_by
    canceled_at
    canceled_by
    car_color
    checking_in
    checking_in_by
    checking_out
    checking_out_by
    comments
    created_at
    created_by
    id
    lessor_id
    name
    output_comments
    plates_number
    status
    transport_type
    updated_at
    visitors_numbers
  }
}
`;

const GETUSERROLEBYEMAIL = gql `
subscription getRole($email: String!) {
  users:users(where: {email: {_eq: $email}}) {
    first_name
    last_name
    email
    roles
    id
  }
}`;

const APARMENTS = gql`
query MyQuery {
  apartment(order_by: {number: asc, building_id: asc}) {
    id
    number
    created_at
    building_id
    user_id
  }
}
`;


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    flexGrow: 1,
  },
  // modal: {
  //   display: 'flex',
  //   padding: theme.spacing(2),
  //   alignItems: 'center',
  //   justifyContent: 'center',
    
  //   height:'adjust',
  //   with:'adjust',
  // },
  modal: {
    display: 'flex',
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
  alignTop:{
    alignItems:'top'
  },
  backColor: {
    backgroundColor: 'white',
  },
  mainContainer: {
    height:250,
    with:300,
    backgroundSize: 'cover'
  }
}));


const VisitForm = props => {
  const { className, handleClose, ...rest } = props;
  const emailCustom =  firebase.auth().currentUser && firebase.auth().currentUser.email;
  const { loading:loadingApartment, error:errorApartment, data:dataApartment } = useQuery(GETAPARTMENT);
  const [addVisit, { data:dataInsertedVisit,loading:loadingInsertedVisit }] = useMutation(INSERTVISIT);
  const { loading:loadingApartment2, error:errorApartment2, data:dataApartment2 } = useQuery(APARMENTS);
  const {data:userData,loading:userLoadingError,error:userDataError} = useSubscription(GETUSERROLEBYEMAIL,{variables:{email:emailCustom}});
  const classes = useStyles();
  const visitorNumber = [];


 for (var i = 0; i < 51; i++) {
      // n += i;
      visitorNumber.push({label:i, value: i});
    }

  const comesByOption = [
    {label:'', value:''},
    {label:'Car', value:'Car'},
    {label:'Airplane', value:'Airplane'},
    {label:'Bus', value:'Bus'}
  ];
  const [values, setValues] = useState({
    name: '',
    visitors_numbers:0,
    arrives_at: '',
    arrives_by: '',
    transport_type: '',
    plates_number: '',
    car_color: '',
    lessor_id: 0,
    apartment_id: 0,
  });

 
  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = event => {
    addVisit({
      variables:{
        "object": values
      }
    }).then(()=>{
      handleClose()
    });
    event.preventDefault();
  };

  if(loadingApartment || userLoadingError || loadingApartment2)
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >loading......</Card>
  )

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        onSubmit={handleSubmit}
        autoComplete="off"
        className={classes.form}
        
      >
        <CardHeader
          subheader="Capture visit information"
          title="New Visit"
          className= {classes.alignTop}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
               <TextField
                fullWidth
                helperText="Owner or Guest"
                label=""
                margin="dense"
                name="name"
                required
                variant="outlined"
                disabled="true"
                value={userData.users[0].first_name + " " + userData.users[0].last_name}>
                  {console.log("buscar: "+userData.users[0].id)}
                
              </TextField>
             
              {/* {(dataApartment.apartment.find(x=>x.id === values.apartment_id))?
              dataApartment.apartment.filter(y=>y.id === values.apartment_id).map((apartment)=>{
                return (
                  <TextField
                    fullWidth
                    helperText=""
                    label="Owner or Guest"
                    margin="dense"
                    name="Huesped"
                    // InputProps={{
                    //   readOnly: true,
                    // }}
                    //onChange={handleChange}
                    required
                    value={`${apartment.user.first_name} ${apartment.user.last_name}`}
                    variant="outlined"
                  />
                );
              }):
              (
                <TextField
                fullWidth
                helperText=""
                label="Owner or Guest"
                margin="dense"
                name="Huesped"
                //onChange={handleChange}
                // InputProps={{
                //   readOnly: false,
                // }}
                required
                value={''}
                variant="outlined"
              />
              )
              } */}
            </Grid>

            <Grid
              item
              md={4}
              xs={12}
              >

              <TextField
                fullWidth
                label="Condominium"
                margin="dense"
                name="apartment_id"
                onChange={handleChange}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.apartment_id}
                variant="outlined"
              >
                <option value={0}>selecionar</option>
                {dataApartment2.apartment.map(apartment => {
                            return (
                            <option
                            key={apartment.id}
                            value={apartment.id}
                            >
                                {`${apartment.number}-${apartment.building_id === 1 ? "a" :
                                                        apartment.building_id === 2 ? "b" :
                                                        apartment.building_id === 3 ? "c" : "d" 
                                                         }`}
                            </option>
                            );
                        })} 
                        
              </TextField> 

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >


              <TextField
                fullWidth
                helperText="Who is comming?"
                label="Name"
                margin="dense"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              {/* <TextField
                fullWidth
                label="Condominium"
                margin="dense"
                name="apartment_id"
                onChange={handleChange}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.apartment_id}
                variant="outlined"
              >
                <option value={0}>selecionar</option>
                {/* {dataApartment.apartment.map(apartment => {
                  return (
                    <option
                    key={apartment.id}
                    value={apartment.id}
                    >
                      {`${apartment.number}-${apartment.building.name}`} */}
                  

              <TextField
                fullWidth
                helperText="When is comming?"
                label="Arrival DateTime"
                margin="dense"
                name="arrives_at"
                onChange={handleChange}
                value={values.arrives_at}
                required
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                // InputProps={{inputProps:{
                //   min: '2020-10-06T22:22:55'
                // }}}
                //inputProps={{ min: '2020-10-06T22:22:55'}}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Comes by"
                margin="dense"
                name="arrives_by"
                onChange={handleChange}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.arrives_by}
                variant="outlined"
              >
                {comesByOption.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Type / model car (optional)"
                label="Type / model car"
                margin="dense"
                name="transport_type"
                onChange={handleChange}
                value={values.transport_type}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Tell us what color (optional)"
                label="Color"
                margin="dense"
                name="car_color"
                onChange={handleChange}
                value={values.car_color}
                variant="outlined"
              />
            </Grid>
          
 
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="plates number"
                // label="plates number"
                margin="dense"
                name="plates_number"
                onChange={handleChange}
                value={values.plates_number}
                variant="outlined"
              />
            </Grid>
          <Grid
              item
              md={4}
              xs={12}>
              <TextField
                fullWidth
                label="Visitors Number"
                margin="dense"
                name="visitors_numbers"
                onChange={handleChange}
                // onClick={setVisitorsArray}
                select
                required
                displayEmpty
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.visitors_numbers}
                variant="outlined"
              >
                {visitorNumber.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
           
</Grid>


        </CardContent>
        <Divider />
        <CardActions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            //onClick={saveInfo}
          >
            Save
          </Button>
          <Button
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

VisitForm.propTypes = {
  className: PropTypes.string
};

export default VisitForm;
